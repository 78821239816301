var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "close-on-click-modal": false,
        top: "10vh",
        width: "60%",
        title: _vm.currentDialogBtn.displayActionName,
        "append-to-body": true,
        "custom-class": "cus-step-dialog",
      },
      on: {
        close: function ($event) {
          return _vm.$emit("update:visible", false)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "form-container",
          style: {
            color: _vm.fontColor,
            borderColor: _vm.borderColor,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form-" + _vm.formConfig.id,
              staticClass: "form-content",
              style: {
                width: "100%",
              },
              attrs: {
                size: _vm.formConfig.container.properties.size,
                model: _vm.formWidgetsValueMap,
                "label-position":
                  _vm.formConfig.container.properties.labelPosition,
                disabled: _vm.formConfig.container.properties.disabled,
                "validate-on-rule-change": false,
                "label-width":
                  _vm.formConfig.container.properties.labelWidth + "px",
              },
            },
            [
              _vm._l(_vm.formWidgets, function (element) {
                return [
                  _vm.formWidgetsRulesMap[element.compCode] &&
                  !_vm.formWidgetsRulesMap[element.compCode].hidden
                    ? [
                        element.compType == "card"
                          ? _c("form-viewer-card", {
                              key: "card-" + element.compCode,
                              style: {
                                marginTop:
                                  _vm.borderColor == "transparent"
                                    ? "10px"
                                    : "0px",
                                borderTopWidth:
                                  _vm.borderColor == "transparent"
                                    ? "0px"
                                    : "1px",
                                borderLeft: _vm.showBorder
                                  ? "1px solid"
                                  : "0px",
                              },
                              attrs: { element: element },
                              on: {
                                trigger: _vm.handleTrigger,
                                changeDataSource: _vm.handleChangeDataSource,
                              },
                            })
                          : element.compType == "row"
                          ? _c("form-viewer-grid", {
                              key: "row-" + element.compCode,
                              style: {
                                borderLeft: _vm.showBorder
                                  ? "1px solid"
                                  : "0px",
                              },
                              attrs: { element: element },
                              on: {
                                trigger: _vm.handleTrigger,
                                changeDataSource: _vm.handleChangeDataSource,
                              },
                            })
                          : element.compType == "table"
                          ? _c("form-viewer-table", {
                              key: "table-" + element.compCode,
                              attrs: {
                                rules:
                                  _vm.formWidgetsRulesMap[element.compCode],
                                element: element,
                              },
                            })
                          : _c("form-viewer-item", {
                              key: "single-" + element.compCode,
                              staticClass: "form-viewer-item",
                              attrs: {
                                widget: element,
                                dataSource:
                                  _vm.formWidgetsDataSourceMap[
                                    element.compCode
                                  ],
                                triggerGroupId:
                                  _vm.formWidgetsTriggerMap[element.compCode],
                                rules:
                                  _vm.formWidgetsRulesMap[element.compCode],
                              },
                              on: {
                                trigger: _vm.handleTrigger,
                                changeDataSource: _vm.handleChangeDataSource,
                              },
                              model: {
                                value:
                                  _vm.formWidgetsValueMap[element.compCode],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formWidgetsValueMap,
                                    element.compCode,
                                    $$v
                                  )
                                },
                                expression:
                                  "formWidgetsValueMap[element.compCode]",
                              },
                            }),
                      ]
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("form-viewer-dialog", {
        attrs: {
          currentDialogBtn: _vm.nestDialogBtn,
          visible: _vm.nestDialogVisible,
          readonly: _vm.readonly,
        },
        on: {
          "update:visible": function ($event) {
            _vm.nestDialogVisible = $event
          },
          trigger: _vm.handleTrigger,
          changeDataSource: _vm.handleChangeDataSource,
          submit: _vm.handleNestDialogSubmit,
        },
      }),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        _vm._l(_vm.footBtn, function (item) {
          return _c(
            "el-button",
            {
              key: item.actionKey,
              style: {
                backgroundColor: _vm.fontColor,
                borderColor: _vm.borderColor,
              },
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.handleBtnClick(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.displayActionName) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }