






















































































import StoreIndex from '@/store/StoreIndex';
import { Component, Prop, Vue, Watch, ProvideReactive, InjectReactive } from 'vue-property-decorator';
import FormViewerDialog from './form-viewer-dialog.vue';
import FormViewerCard from '@/components/form-design/viewer/form-viewer-card.vue';

import FormViewerTable from '@/components/form-design/viewer/form-viewer-table.vue';
import FormViewerTab from '@/components/form-design/viewer/form-viewer-tab.vue';
import FormViewerGrid from '@/components/form-design/viewer/form-viewer-grid.vue';
import FormViewerItem from '@/components/form-design/viewer/form-viewer-item.vue';
import { FormData, FormModel } from '@/models/form/FormModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import {
  getFormConfig,
  getDataInstanceDetail,
  getAllWidgetsHistoryData,
  validWidgetCondition,
  getAllWidgetsDefaultData
} from '@/api/form-design/FormApi';
import { WidgetModel } from '@/models/form/WidgetModel';
import { NodeActionModel, ActionTypeEnum, ActionOperationType } from '@/models/flow/GraphNodeModel';
import { getFormConfig as getFormConfigCache, getFormWidgetsByCompType, buildFormWidgetMap, buildFormDataForSave } from './utils';

@Component({
  name: 'FormViewer',
  components: {
    FormViewerItem,
    FormViewerDialog,
    FormViewerCard,
    FormViewerTable,
    FormViewerTab,
    FormViewerGrid
  }
})
export default class extends Vue {
  @Prop({ default: false }) readonly!: boolean; // 是否是查看，为true时全部为只读
  @Prop({ default: () => {} }) btnObj!: NodeActionModel; // 流程图中设置
  @Prop({ default: 'flow' }) operation?: string;
  @Prop() formContainer?: string;

  @InjectReactive() flowId: any;
  @InjectReactive() formId: any;
  @InjectReactive() formDataId: any;

  @ProvideReactive() formWidgetsValueMap: any = {};
  @ProvideReactive() formWidgetsDataSourceMap: any = {};
  @ProvideReactive() formWidgetsRulesMap: any = {}; // 当前页面的表单校验规则
  @ProvideReactive() formWidgetsTriggerMap: any = {}; // 当前页面的表单校验规则

  @ProvideReactive() defaultTableWidgetsValueMap: any = {}; //表格每行的校验规则
  @ProvideReactive() defaultTableWidgetsRulesMap: any = {}; //表格每行的校验规则
  @ProvideReactive() defaultTableWidgetsDataSourceMap: any = {}; //表格每行的表单项，每项的数据源

  @ProvideReactive() historyTableWidgetsDataSourceMap: any = {}; //回显时表格每行的校验规则
  @ProvideReactive() historyTableWidgetsRulesMap: any = {}; //回显时表格每行的表单项，每项的数据源
  @ProvideReactive() historyTableWidgetsTriggerMap: any = {}; //回显时表格每行的表单项，每项的数据源

  formDataLoading: boolean = false;
  formConfig: FormModel = SystemUtil.cloneDeep(FormData);
  formWidgetMap: any = {};

  formWidgets: any[] = [];
  formWidgetsStack: any = {};

  @ProvideReactive()
  formWidth: number = 0;
  @ProvideReactive()
  showBorder: boolean = false; // 是否显示边框
  @ProvideReactive()
  marginWidth: number = 0;
  marginColor: string = '#ccc';
  @ProvideReactive()
  showCellBorder: boolean = false; // 是否显示边框

  borderColor: string = 'transparent';
  fontColor: string = '';

  currentDialogBtn: any = {}; // 当前点击的按钮
  currentDialogVisible: boolean = false;
  btnObjStack: NodeActionModel[] = [];

  defaultWidgetsValueMap: any = {}; // 表单项默认值集合
  defaultWidgetsDataSourceMap: any = {}; // 表单项默认数据源集合
  defaultWidgetsOperationMap: any = {}; // 表单项默认操作属性集合
  defaultWidgetsTriggerMap: any = {};

  historyWidgetsValueMap: any = {}; // 表单项回显值集合
  historyWidgetsDataSourceMap: any = {}; // 表单项回显数据源集合
  historyWidgetsOperationMap: any = {}; // 表单项回显操作属性集合
  historyWidgetsTriggerMap: any = {}; // 表单项回显操作属性集合

  labelWidth: number = 120;

  get _activedNode() {
    return this.$store.getters.activedNode;
  }
  get _formSetting() {
    return {};
  }

  created() {
    this.buildFormConfig(this.formId);
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.buildElementWidth);
  }

  /**
   * 构建流程节点或者按钮中配置的表单项权限map
   */
  buildFlowWidgetsRulesMap(componentsSetting) {
    let formWidgetsRulesMap = {};
    componentsSetting.forEach(compItem => {
      formWidgetsRulesMap[compItem.componentsCode] = compItem;
      if (compItem.children && compItem.children.length) {
        formWidgetsRulesMap = Object.assign(formWidgetsRulesMap, this.buildFlowWidgetsRulesMap(compItem.children));
      }
    });
    return formWidgetsRulesMap;
  }

  /*
   *@description: 获取表单配置详情
   *@author: gongcaixia
   *@date: 2021-05-19 14:34:09
   */
  buildFormConfig(formId) {
    if (formId == 'null') {
      return false;
    }
    if (formId) {
      getFormConfig({ id: formId }).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          let formConfig = res.data;
          this.formConfig = formConfig;
          this.$store.commit('SET_FORM_CONFIG', this.formConfig);
          this.formWidgetMap = buildFormWidgetMap(formConfig.container.components);
          this.buildFormTheme(formConfig);
          this.buildAllWidgetData(formConfig); // 获取数据源datalist
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }

  /**
   * 构建表单主题（边框，字体等）
   */
  buildFormTheme(formConfig) {
    if (formConfig && formConfig.container.properties) {
      if (formConfig.container.properties.hasBorder) {
        this.showBorder = true;
        this.showCellBorder = formConfig.container.properties.cellBorder || false;
        this.borderColor = formConfig.container.properties.borderColor;
      }
      this.fontColor = formConfig.container.properties.fontColor;
      this.marginColor = formConfig.container.properties.marginColor || '#ffffff';
    } else {
      this.borderColor = 'transparent';
      this.marginColor = '#ffffff';
    }
    this.buildElementWidth(formConfig);
    window.addEventListener('resize', () => {
      this.buildElementWidth(formConfig);
    });
  }

  buildElementWidth(formConfig?) {
    if (formConfig) {
      // this.marginWidth = ((formConfig.container.properties.margin || 0) * screenWidth) / 1920;
      this.marginWidth = formConfig.container.properties.margin || 0;
      this.marginColor = formConfig.container.properties.marginColor || '#ffffff';
      this.labelWidth = formConfig.container.properties.labelWidth || 120;
      // let labelWidth = formConfig.container.properties.labelWidth || 120;
    }

    // let result = (labelWidth * this.formWidth) / 1920 - 14;
    // this.formWidth = 1200 - 24 - this.marginWidth * 2 - 2;
    this.formWidth = this.$el.clientWidth - this.marginWidth * 2 - 10;
  }

  /**
   * 构建表单所有组件的数据源、默认值、操作权限
   */
  buildAllWidgetData(formConfig) {
    if (this.formDataId) {
      this.buildAllWidgetHistoryData(this.formDataId, formConfig);
    } else {
      this.buildAllWidgetDefaultData(formConfig);
    }
  }

  /**
   * 构建表单所有组件默认的数据源、值、操作权限
   */
  buildAllWidgetDefaultData(formConfig) {
    let params: any = {
      formId: formConfig.id
    };

    this.handleWidgetDefaultDataRequest(params).then(res => {
      if (res.success) {
        this.defaultWidgetsValueMap = res.data.widgetValueMap; // 表单项默认值集合
        this.defaultWidgetsDataSourceMap = res.data.widgetDataSourcesMap; // 表单项默认数据源集合
        this.defaultWidgetsOperationMap = res.data.widgetOperationsMap; // 表单项默认操作属性集合
        this.defaultWidgetsTriggerMap = res.data.widgetTriggerMap;
        this.defaultTableWidgetsValueMap = res.data.tableWidgetsValueMap; // 表单项默认值集合
        this.defaultTableWidgetsRulesMap = res.data.tableWidgetsRulesMap; // 表单项默认数据源集合
        this.defaultTableWidgetsDataSourceMap = res.data.tableWidgetDataSourcesMap; // 表单项默认操作属性集合
        this.buildFormDataForRender();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 构建表单的数据源
  handleWidgetDefaultDataRequest(params) {
    return new Promise<any>((resolve, reject) => {
      getAllWidgetsDefaultData(params).then((res: any) => {
        if (res.code == '1' && res.data) {
          let data = this.buildWidgetDataResponse(res.data);
          resolve({
            success: true,
            data: data
          });
        } else {
          resolve({ success: false, message: res.message });
        }
      });
    });
  }

  /**
   * 构建表单表格组件回显的数据源、值、操作权限
   */
  buildAllWidgetHistoryData(formDataId, formConfig) {
    let params: any = {
      formId: formConfig.id
    };
    if (formDataId) {
      params.formDataId = formDataId;
    }

    this.handleWidgetsHistoryDataRequest(params).then(res => {
      if (res.success) {
        // 构建表格组件默认数据
        this.historyWidgetsDataSourceMap = res.data.widgetDataSourcesMap; // 表单项默认数据源集合
        this.historyWidgetsOperationMap = res.data.widgetOperationsMap; // 表单项默认操作属性集合
        this.historyWidgetsValueMap = res.data.widgetValueMap;
        this.historyWidgetsTriggerMap = res.data.widgetTriggerMap;

        this.historyTableWidgetsDataSourceMap = res.data.tableWidgetDataSourcesMap;
        this.historyTableWidgetsRulesMap = res.data.tableWidgetsRulesMap;
        this.historyTableWidgetsTriggerMap = res.data.tableWidgetsTriggerMap;
        if (res.data.tableWidgetsValueMap && Object.keys(res.data.tableWidgetsValueMap).length) {
          for (const tableCompCode in res.data.tableWidgetsValueMap) {
            this.historyWidgetsValueMap[tableCompCode] = res.data.tableWidgetsValueMap[tableCompCode];
          }
        }
        // debugger;
        let tableWidgets = getFormWidgetsByCompType(formConfig.container.components, 'table');
        if (tableWidgets && tableWidgets.length) {
          // 如果表单中有表格，则获取表格每行表单项的默认数据源、值、操作权限
          delete params.formDataId;
          params.parentModelCodes = tableWidgets.map(item => item.compCode);
          this.handleWidgetDefaultDataRequest(params).then(res => {
            this.defaultTableWidgetsValueMap = res.data.tableWidgetsValueMap; // 表单项默认值集合
            this.defaultTableWidgetsRulesMap = res.data.tableWidgetsRulesMap; // 表单项默认数据源集合
            this.defaultTableWidgetsDataSourceMap = res.data.tableWidgetDataSourcesMap; // 表单项默认操作属性集合
            this.buildFormHistoryData(formDataId, formConfig); // 获取回显的表单数据
          });
        } else {
          this.buildFormHistoryData(formDataId, formConfig); // 获取回显的表单数据
        }
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 构建表单的数据源
  handleWidgetsHistoryDataRequest(params) {
    return new Promise<any>((resolve, reject) => {
      getAllWidgetsHistoryData(params).then((res: any) => {
        if (res.code == '1' && res.data) {
          let data = this.buildWidgetDataResponse(res.data);
          resolve({
            success: true,
            data: data
          });
        } else {
          resolve({ success: false, message: res.message });
        }
      });
    });
  }

  buildWidgetDataResponse(data) {
    let simpleDataList: any[] = data.simpleDataList || [];
    let complexDataList: any[] = data.complexDataList || [];
    let noDataModelList: any[] = data.noDataModelList || [];
    let allDataList = simpleDataList.concat(complexDataList).concat(noDataModelList);
    let widgetValueMap = {}; // 表单项默认值集合
    let widgetDataSourcesMap = {}; // 表单项默认数据源集合
    let widgetOperationsMap = {}; // 表单项默认操作属性集合

    let widgetTriggerMap = {}; // 表单项默认值集合

    let tableWidgetsValueMap = {}; // 表格中的表单项默认值集合
    let tableWidgetDataSourcesMap = {}; // 表格中的表单项默认数据源集合
    let tableWidgetsRulesMap = {}; // 表格中的表单项默认操作属性集合
    let tableWidgetsTriggerMap = {}; // 表格中的表单项触发器集合

    allDataList.forEach(item => {
      if (!item.formModelParentCode || !item.formModelParentCode.includes('table_')) {
        // 如果组件不是表格内组件
        // 组合默认值
        if (item.defaultValue) {
          widgetValueMap[item.formModelCode] = item.defaultValue;
        }
        // 组合数据源
        if (item.dataList) {
          widgetDataSourcesMap[item.formModelCode] = item.dataList;
        } else if (item.pageInfo) {
          widgetDataSourcesMap[item.formModelCode] = item.pageInfo;
        }
        // 组合操作权限
        if (item.operation) {
          widgetOperationsMap[item.formModelCode] = item.operation;
        }
        // 组合默认值
        if (item.triggerGroupId) {
          widgetTriggerMap[item.formModelCode] = item.triggerGroupId;
        }
      } else {
        // 如果组件是表格内组件
        let formModelCode = item.formModelParentCode;
        let rowIndex = item.rowIndex;
        if (item.defaultValue) {
          if (!tableWidgetsValueMap[formModelCode]) {
            tableWidgetsValueMap[formModelCode] = [];
          }
          if (!tableWidgetsValueMap[formModelCode][rowIndex]) {
            tableWidgetsValueMap[formModelCode][rowIndex] = {};
          }
          tableWidgetsValueMap[formModelCode][rowIndex][item.formModelCode] = item.defaultValue;
        }

        if (item.dataList || item.pageInfo) {
          if (!tableWidgetDataSourcesMap[formModelCode]) {
            tableWidgetDataSourcesMap[formModelCode] = [];
          }
          if (!tableWidgetDataSourcesMap[formModelCode][rowIndex]) {
            tableWidgetDataSourcesMap[formModelCode][rowIndex] = {};
          }
          if (item.dataList) {
            tableWidgetDataSourcesMap[formModelCode][rowIndex][item.formModelCode] = item.dataList;
          } else if (item.pageInfo) {
            tableWidgetDataSourcesMap[formModelCode][rowIndex][item.formModelCode] = item.pageInfo;
          }
        }

        if (item.operation) {
          if (!tableWidgetsRulesMap[formModelCode]) {
            tableWidgetsRulesMap[formModelCode] = [];
          }
          if (!tableWidgetsRulesMap[formModelCode][rowIndex]) {
            tableWidgetsRulesMap[formModelCode][rowIndex] = {};
          }
          tableWidgetsRulesMap[formModelCode][rowIndex][item.formModelCode] = item.operation;
        }

        if (item.triggerGroupId) {
          if (!tableWidgetsTriggerMap[formModelCode]) {
            tableWidgetsTriggerMap[formModelCode] = [];
          }
          if (!tableWidgetsTriggerMap[formModelCode][rowIndex]) {
            tableWidgetsTriggerMap[formModelCode][rowIndex] = {};
          }
          tableWidgetsTriggerMap[formModelCode][rowIndex][item.formModelCode] = item.triggerGroupId;
        }
      }
    });
    return {
      widgetValueMap,
      widgetDataSourcesMap,
      widgetOperationsMap,
      widgetTriggerMap,
      tableWidgetsValueMap,
      tableWidgetDataSourcesMap,
      tableWidgetsRulesMap,
      tableWidgetsTriggerMap
    };
  }

  // 访问待办、已办等详情页时，获取其详情数据
  buildFormHistoryData(formDataId, formConfig) {
    let formWidgetsValueMap: any = {};
    getDataInstanceDetail({ formDataId, formId: formConfig.id }).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        res.data.map(item => {
          let historyData = this.historyWidgetsValueMap[item.formModelCode];
          if (!historyData) {
            if (item.compType == 'table') {
              formWidgetsValueMap[item.formModelCode] = item.children;
            } else {
              formWidgetsValueMap[item.formModelCode] = item;
            }
          } else {
            if (item.compType == 'table') {
              let tableData: any[] = [];
              item.children.forEach((rowData, rowIndex) => {
                tableData.push({ ...rowData, ...historyData[rowIndex] });
              });
              formWidgetsValueMap[item.formModelCode] = tableData;
            } else {
              formWidgetsValueMap[item.formModelCode] = historyData;
            }
          }
        });
        this.historyWidgetsValueMap = formWidgetsValueMap;
        this.buildFormDataForRender();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 如果配置了页面加载触发器，则处理相应的触发动作
  buildFormDataForRender() {
    this.buildFormDataSourceOnLoaded();
    this.buildFormValueOnLoaded();
    this.buildFormWidgetsOnLoad();
    this.buildFormRulesOnLoaded();
    this.buildFormTriggersOnLoaded();
    this.formDataLoading = true;
  }

  /**
   * 构造最终的表单数据（处理好各种触发器的东西）
   */
  buildFormDataSourceOnLoaded() {
    this.formWidgetsDataSourceMap = {
      ...this.defaultWidgetsDataSourceMap,
      ...this.historyWidgetsDataSourceMap
    };
  }

  /**
   * 构造最终的表单数据（处理好各种触发器的东西）
   */
  buildFormValueOnLoaded() {
    this.formWidgetsValueMap = {
      ...this.defaultWidgetsValueMap,
      ...this.historyWidgetsValueMap
    };
  }

  /**
   * 构建表单项的规则
   * @param {number} param
   * @returns {number}
   */
  buildFormRulesOnLoaded() {
    let nodeFormSetting = this.operation == 'task_startup' ? {} : this._formSetting;
    let formWidgetsRulesMap = {
      ...this.defaultWidgetsOperationMap,
      ...this.historyWidgetsOperationMap
    };
    // 构建表单权限
    this.formWidgetsRulesMap = this.buildSimpleWidgetsRules(formWidgetsRulesMap, nodeFormSetting);
    // 构建表格默认权限（用于新增）
    this.defaultTableWidgetsRulesMap = this.buildTableWidgetsRules(this.defaultTableWidgetsRulesMap, nodeFormSetting);
    // 构建表格回显的权限， 用于待办等回显 Todo
    this.historyTableWidgetsRulesMap = this.buildTableWidgetsRules(this.historyTableWidgetsRulesMap, nodeFormSetting);
  }

  /**
   * 构建表单上 非表格 表单项的操作权限
   */
  buildSimpleWidgetsRules(formWidgetsRulesMap, _formSetting) {
    let result: any = {};
    for (const compCode in formWidgetsRulesMap) {
      let compName = this.findWidgetByCode(compCode)?.compName;
      let temp = {
        required: false,
        disabled: false,
        hidden: false,
        label: compName
      };
      let operation = formWidgetsRulesMap[compCode];
      if (_formSetting && _formSetting[compCode] && _formSetting[compCode].operation) {
        operation = _formSetting[compCode].operation;
      }
      if (operation != 'default') {
        temp[operation] = true;
      }
      if (this.readonly) {
        temp.disabled = true;
      }
      result[compCode] = temp;
    }
    return result;
  }

  buildTableWidgetsRules(tablesWidgetsRulesMap, _formSetting) {
    let result: any = {};
    for (const compCode in tablesWidgetsRulesMap) {
      result[compCode] = [];
      let tableRules = tablesWidgetsRulesMap[compCode];
      tableRules.forEach(rowRules => {
        let tableRowRules = this.buildSimpleWidgetsRules(rowRules, _formSetting);
        result[compCode].push(tableRowRules);
      });
    }
    return result;
  }

  /**
   * 构建页面显示的组件
   */
  buildFormWidgetsOnLoad() {
    let components = this.formConfig.container.components;
    if (this._formSetting && Object.keys(this._formSetting).length) {
      // 根据流程节点上的表单配置，构建表单项的校验规则
      this.buildFormWidgetsByNode(components, this._formSetting);
    } else {
      // 根据表单配置，构建表单项的校验规则
      this.buildFormWidgetsByForm(components);
    }
  }

  /**
   * 根据节点配置获取当前配置的表单项集合
   */
  buildFormWidgetsByNode(components, formSettingInNode) {
    if (this.formWidgetsStack.page_inited) {
      this.formWidgets = this.formWidgetsStack.page_inited;
    } else {
      if (components && components.length) {
        let formWidgets = this.buildFormWidgetsFn(components, formSettingInNode);
        this.formWidgets = this.buildFormWidgtsByOrder(formWidgets, formSettingInNode);
      } else {
        this.formWidgets = [];
      }
      this.formWidgetsStack.page_inited = this.formWidgets;
    }
  }

  // 递归构建节点表单项
  private buildFormWidgetsFn(allComponents, filterCodesMap) {
    let result: any = [];
    allComponents.forEach(compItem => {
      if (filterCodesMap.hasOwnProperty(compItem.compCode)) {
        if (compItem.compType == 'card') {
          compItem.properties.components = this.buildFormWidgetsFn(compItem.properties.components, filterCodesMap);
        } else if (compItem.compType == 'row') {
          compItem.properties.gridColumns = compItem.properties.gridColumns.filter(gridCol => {
            gridCol.components = this.buildFormWidgetsFn(gridCol.components, filterCodesMap);
            return gridCol;
          });
        } else if (compItem.compType == 'table') {
          compItem.properties.components = this.buildFormWidgetsFn(compItem.properties.components, filterCodesMap);
        }
        result.push(compItem);
      }
    });
    return result;
  }

  buildFormWidgtsByOrder(formWidgets, formSetting) {
    formWidgets.forEach(compItem => {
      if (compItem.compType == 'card') {
        compItem.properties.components = compItem.properties.components.sort((prev, next) => {
          return formSetting[prev.compCode].locationIndex - formSetting[next.compCode].locationIndex;
        });
      } else if (compItem.compType == 'row') {
        compItem.properties.gridColumns = compItem.properties.gridColumns.sort(gridCol => {
          gridCol.components = gridCol.components.sort((prev, next) => {
            return formSetting[prev.compCode].locationIndex - formSetting[next.compCode].locationIndex;
          });
        });
      } else if (compItem.compType == 'table') {
        compItem.properties.components = compItem.properties.components.sort((prev, next) => {
          return formSetting[prev.compCode].locationIndex - formSetting[next.compCode].locationIndex;
        });
      }
    });
    return formWidgets.sort((prev, next) => {
      return formSetting[prev.compCode].locationIndex - formSetting[next.compCode].locationIndex;
    });
  }

  /**
   * 当表单不关联流程时，页面显示项为当前表单的所有表单项
   */
  buildFormWidgetsByForm(components) {
    if (this.formWidgetsStack.page_inited) {
      this.formWidgets = [...this.formWidgetsStack.page_inited];
    } else {
      this.formWidgets = components || [];
      this.formWidgetsStack.page_inited = [...this.formWidgets];
    }
  }

  /**
   * 根据按钮配置，构建表单组件项集合
   */
  buildFormWidgetsByBtn(currentBtn) {
    if (this.formWidgetsStack[currentBtn.actionKey]) {
      this.formWidgets = this.formWidgetsStack[currentBtn.actionKey];
    } else {
      let formSettingInBtn = currentBtn.formSetting.componentsSettings;
      let components = this.formConfig.container.components;
      if (components && components.length) {
        let filterCodesMap = this.buildFlowWidgetsRulesMap(formSettingInBtn);
        this.formWidgets = this.buildFormWidgetsFn(components, filterCodesMap);
      } else {
        this.formWidgets = [];
      }
      this.formWidgetsStack[currentBtn.actionKey] = this.formWidgets;
    }
  }

  buildFormTriggersOnLoaded() {
    this.formWidgetsTriggerMap = {
      ...this.defaultWidgetsTriggerMap,
      ...this.historyWidgetsTriggerMap
    };
  }

  /**
   * 改变组件数据源时
   */
  handleChangeDataSource({ element, dataSource }) {
    if (!this.formWidgetsDataSourceMap[element.compCode]) {
      this.$set(this.formWidgetsDataSourceMap, element.compCode, dataSource || []);
    } else {
      this.formWidgetsDataSourceMap[element.compCode] = dataSource || [];
    }
  }

  /**
   * 点击按钮时
   */
  handleOperation(operationBtn) {
    if (operationBtn.actionType === ActionTypeEnum.FLUSH) {
      this.btnObjStack.push(operationBtn);
      this.buildWidgetRulesByBtn(operationBtn);
      this.buildFormWidgetsByBtn(operationBtn);
    } else if (operationBtn.actionType === ActionTypeEnum.BACK && this.btnObjStack.length) {
      this.btnObjStack.pop();
      if (this.btnObjStack.length) {
        let currentBtn = this.btnObjStack[this.btnObjStack.length - 1];
        this.buildFormWidgetsByBtn(currentBtn);
      } else {
        this.buildFormWidgetsOnLoad();
      }
    } else if (operationBtn.actionType === ActionTypeEnum.DIALOG) {
      // this.buildWidgetRulesByBtn(operationBtn);
      this.currentDialogBtn = { ...operationBtn };
      this.currentDialogVisible = true;
    }
  }

  /**
   * 根据按钮配置，构建表单组件项的校验规则集合
   */
  buildWidgetRulesByBtn(currentBtn) {
    let componentsSetting = currentBtn.formSetting.componentsSettings;
    let btnFormSetting = this.buildFlowWidgetsRulesMap(componentsSetting);
    this.formWidgetsRulesMap = this.buildBtnWidgetsRules(this.formWidgetsRulesMap, btnFormSetting);
  }

  /**
   * 构建表单上 非表格 表单项的操作权限
   */
  buildBtnWidgetsRules(formWidgetsRulesMap, _formSetting) {
    let result: any = { ...formWidgetsRulesMap };
    for (const compCode in _formSetting) {
      if (result && result[compCode]) {
        let operation = '';
        if (_formSetting[compCode]) {
          operation = _formSetting[compCode];
        }
        if (operation != 'default') {
          result[compCode][operation] = true;
        } else {
          result[compCode].required = false;
          result[compCode].disabled = false;
          result[compCode].hidden = false;
        }
        if (this.readonly) {
          result[compCode].disabled = true;
        }
      }
    }
    return result;
  }

  /**
   * description  点击保存或者提交时，获取当前表单数据(其他页面会通过ref调用,不要删)
   * @param {}
   * @returns {}
   */
  getFormDataForSave(validate: boolean = true) {
    StoreIndex.commit('SET_ALL_SAVE');
    return new Promise((resolve, reject) => {
      let params = {
        formId: this.formConfig.id,
        id: this.formDataId || null,
        formModelDataList: buildFormDataForSave(this.formWidgetsValueMap, this.formDataId).filter(a => {
          return a.formModelCode;
        })
      };
      if (validate) {
        (this.$refs['form-' + this.formConfig.id] as any).validate((v, object) => {
          if (v) {
            resolve(params);
          } else {
            setTimeout(() => {
              var isError: any = document.getElementsByClassName('is-error');
              if (isError && isError.length) {
                let dom = isError[0];
                //   //第一种方法（包含动画效果）
                dom.scrollIntoView({
                  //滚动到指定节点
                  block: 'center', //值有start,center,end，nearest，当前显示在视图区域中间
                  behavior: 'smooth' //值有auto、instant,smooth，缓动动画（当前是慢速的）
                });
              }
            }, 1);
            reject(v);
          }
        });
      } else {
        resolve(params);
      }
    });
  }

  /**
   * 处理触发器
   * @param {number} param
   * @returns {number}
   */
  handleTrigger({ widget }, rowIndex?: number) {
    let params: any = {
      formId: this.formId,
      formModelDataList: buildFormDataForSave(this.formWidgetsValueMap, this.formDataId).filter(a => a.formModelCode),
      formModelId: widget.id
    };
    if (this.formDataId) {
      params.formDataId = this.formDataId;
    }
    if (rowIndex || rowIndex == 0) {
      params.rowIndex = rowIndex;
    }

    // 校验action
    validWidgetCondition(params).then((res: any) => {
      if (res.code == '1') {
        if (res.data && res.data) {
          let triggerResult = res.data;
          let noDataModelList: any[] = triggerResult.noDataModelList || [];
          let complexDataSource: any[] = triggerResult.complexDataList || [];
          let simpleDataSource: any[] = triggerResult.simpleDataList || [];
          let allDataSource = simpleDataSource.concat(complexDataSource).concat(noDataModelList);
          allDataSource.forEach(item => {
            let target = this.findWidgetByCode(item.formModelCode);
            if (item.dataList || item.pageInfo) {
              let dataSource = item.dataList || item.pageInfo;
              this.replaceWidgetDataSource(target, dataSource);
            }
            if (item.hasOwnProperty('defaultValue')) {
              this.replaceWidgetDataValue(target, item.defaultValue);
            }
            if (item.operation) {
              this.replaceWidgetOperation(target, item.operation);
            }
            if (item.triggerGroupId) {
              this.replaceWidgetTrigger(target, item.triggerGroupId);
            }
          });
        }
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 替换默认值,如果之前没有值
  replaceWidgetDataValue(widget: any, defaultValue: any) {
    if (!this.formWidgetsValueMap[widget.compCode]) {
      this.$set(this.formWidgetsValueMap, widget.compCode, defaultValue);
    } else {
      this.formWidgetsValueMap[widget.compCode] = defaultValue;
    }
  }
  // 构建表单项的数据源
  replaceWidgetDataSource(widget, dataSource) {
    if (widget.dataSource) {
      if (!this.formWidgetsDataSourceMap[widget.compCode]) {
        this.$set(this.formWidgetsDataSourceMap, widget.compCode, dataSource);
      } else {
        this.formWidgetsDataSourceMap[widget.compCode] = dataSource;
      }
    }
  }
  // 构建表单项的操作属性
  replaceWidgetOperation(widget, operation) {
    let tmp = {
      required: false,
      hidden: false,
      disabled: false,
      label: widget.compName
    };
    if (operation != 'default') {
      tmp[operation] = true;
    }
    if (!this.formWidgetsRulesMap[widget.compCode]) {
      this.$set(this.formWidgetsRulesMap, widget.compCode, tmp);
    } else {
      this.formWidgetsRulesMap[widget.compCode] = tmp;
    }
  }

  // 构建表单项的操作属性
  replaceWidgetTrigger(widget, triggerGroupId) {
    if (!this.formWidgetsTriggerMap[widget.compCode]) {
      this.$set(this.formWidgetsTriggerMap, widget.compCode, triggerGroupId);
    } else {
      this.formWidgetsTriggerMap[widget.compCode] = triggerGroupId;
    }
  }

  /**
   * 根据表单项code获取表单项
   */
  findWidgetByCode(code: string): WidgetModel | undefined {
    return this.formWidgetMap[code];
  }

  // 弹出框点击按钮
  handleDialogSubmit(btnObj: NodeActionModel) {
    if (btnObj.actionType === ActionTypeEnum.PROCESS) {
      if (btnObj.operation !== ActionOperationType.SAVE) {
        (<any>this.$refs['form-' + this.formConfig.id]).validate(v => {
          if (v) {
            this.$emit('submit', btnObj);
            this.$emit('update:visible', false);
          } else {
            this.$message.warning(this.$t('lang_viladate_fail') as any);
          }
        });
      } else {
        this.$emit('submit', btnObj);
        this.$emit('update:visible', false);
      }
    }
  }
  @Watch('formId')
  formIdWatcher(newVal, oldVal) {
    if (newVal) {
      this.buildFormConfig(newVal);
    }
  }

  @Watch('btnObj')
  changeBtnId(newVal, oldVal) {
    if (newVal !== oldVal && newVal) {
      this.handleOperation(newVal);
    }
  }
}
