import { render, staticRenderFns } from "./form-viewer.vue?vue&type=template&id=d9610a1a&scoped=true&"
import script from "./form-viewer.vue?vue&type=script&lang=ts&"
export * from "./form-viewer.vue?vue&type=script&lang=ts&"
import style0 from "./form-viewer.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./form-viewer.vue?vue&type=style&index=1&id=d9610a1a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9610a1a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\123\\river-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d9610a1a')) {
      api.createRecord('d9610a1a', component.options)
    } else {
      api.reload('d9610a1a', component.options)
    }
    module.hot.accept("./form-viewer.vue?vue&type=template&id=d9610a1a&scoped=true&", function () {
      api.rerender('d9610a1a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form-home-viewer/form-viewer.vue"
export default component.exports