var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-container",
      style: {
        color: _vm.fontColor,
        borderColor: _vm.borderColor,
        padding: "0px " + _vm.marginWidth + "px",
        background: _vm.formContainer ? _vm.formContainer : _vm.marginColor,
      },
    },
    [
      _vm.formDataLoading
        ? _c(
            "el-form",
            {
              ref: "form-" + _vm.formConfig.id,
              staticClass: "form-content",
              style: {
                height: "100%",
                background: "#E8EDF4",
                width: _vm.formWidth + "px",
              },
              attrs: {
                size: _vm.formConfig.container.properties.size,
                model: _vm.formWidgetsValueMap,
                "label-position":
                  _vm.formConfig.container.properties.labelPosition,
                disabled: _vm.formConfig.container.properties.disabled,
                "validate-on-rule-change": false,
                labelWidth: _vm.labelWidth + "px",
              },
            },
            [
              _vm._l(_vm.formWidgets, function (element) {
                return [
                  _vm.formWidgetsRulesMap[element.compCode] &&
                  !_vm.formWidgetsRulesMap[element.compCode].hidden
                    ? [
                        element.compType == "card"
                          ? _c("form-viewer-card", {
                              key: "card-" + element.compCode,
                              style: {
                                marginTop:
                                  _vm.borderColor == "transparent"
                                    ? "10px"
                                    : "0px",
                                borderTopWidth:
                                  _vm.borderColor == "transparent"
                                    ? "0px"
                                    : "1px",
                                borderLeft: _vm.showBorder
                                  ? "1px solid"
                                  : "0px",
                              },
                              attrs: { element: element },
                              on: {
                                trigger: _vm.handleTrigger,
                                changeDataSource: _vm.handleChangeDataSource,
                              },
                            })
                          : element.compType == "row"
                          ? _c("form-viewer-grid", {
                              key: "row-" + element.compCode,
                              style: {
                                borderLeft: _vm.showBorder
                                  ? "1px solid"
                                  : "0px",
                              },
                              attrs: { element: element },
                              on: {
                                trigger: _vm.handleTrigger,
                                changeDataSource: _vm.handleChangeDataSource,
                              },
                            })
                          : element.compType == "table"
                          ? _c("form-viewer-table", {
                              key: "table-" + element.compCode,
                              attrs: {
                                rules:
                                  _vm.formWidgetsRulesMap[element.compCode],
                                element: element,
                              },
                            })
                          : element.compType == "tab"
                          ? _c("form-viewer-tab", {
                              key: "tab-" + element.compCode,
                              attrs: {
                                element: element,
                                dataSource: _vm.formWidgetsDataSourceMap,
                              },
                              on: {
                                changeDataSource: _vm.handleChangeDataSource,
                              },
                            })
                          : _c("form-viewer-item", {
                              key: "single-" + element.compCode,
                              staticClass: "form-viewer-item",
                              attrs: {
                                widget: element,
                                dataSource:
                                  _vm.formWidgetsDataSourceMap[
                                    element.compCode
                                  ],
                                triggerGroupId:
                                  _vm.formWidgetsTriggerMap[element.compCode],
                                rules:
                                  _vm.formWidgetsRulesMap[element.compCode],
                              },
                              on: {
                                changeDataSource: _vm.handleChangeDataSource,
                                trigger: _vm.handleTrigger,
                              },
                              model: {
                                value:
                                  _vm.formWidgetsValueMap[element.compCode],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formWidgetsValueMap,
                                    element.compCode,
                                    $$v
                                  )
                                },
                                expression:
                                  "formWidgetsValueMap[element.compCode]",
                              },
                            }),
                      ]
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c("form-viewer-dialog", {
        attrs: {
          readonly: _vm.readonly,
          visible: _vm.currentDialogVisible,
          currentDialogBtn: _vm.currentDialogBtn,
        },
        on: {
          "update:visible": function ($event) {
            _vm.currentDialogVisible = $event
          },
          trigger: _vm.handleTrigger,
          submit: _vm.handleDialogSubmit,
          changeDataSource: _vm.handleChangeDataSource,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }