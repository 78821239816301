

































































































import FormViewerItem from './form-viewer-item.vue';
import FormViewerCard from './form-viewer-card.vue';
import FormViewerTable from './form-viewer-table.vue';
import FormViewerGrid from './form-viewer-grid.vue';
import { Vue, Prop, InjectReactive, Component, Watch } from 'vue-property-decorator';
import { FormModel, FormData } from '@/models/form/FormModel';
import { ActionOperationType, ActionTypeEnum, NodeActionModel } from '@/models/flow/GraphNodeModel';
import { getFormConfig } from './utils';
@Component({
  name: 'FormViewerDialog',
  components: {
    FormViewerItem,
    FormViewerCard,
    FormViewerTable,
    FormViewerGrid
  }
})
export default class FormViewerDialog extends Vue {
  @Prop({
    default() {
      return {};
    }
  })
  currentDialogBtn!: NodeActionModel;

  @Prop() visible!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @InjectReactive() flowId: any;
  @InjectReactive() formId!: string;
  @InjectReactive() formDataId!: string;
  @InjectReactive() formWidgetsValueMap!: any;
  @InjectReactive() formWidgetsRulesMap!: any;
  @InjectReactive() formWidgetsDataSourceMap!: any;
  @InjectReactive() formWidgetsTriggerMap!: any;
  @InjectReactive() showBorder!: boolean;

  formConfig: FormModel = Object.assign({}, FormData);
  formWidgets: any[] = [];
  formWidgetsStack: any = {};

  footBtn: NodeActionModel[] = [];
  borderColor: string = 'transparent';
  fontColor: string = '';

  btnObjStack: Array<NodeActionModel> = []; // 流程按钮堆栈
  nestDialogBtn: any = {};
  nestDialogVisible: boolean = false;

  created() {
    this.initData();
  }

  initData() {
    if (this.currentDialogBtn && this.currentDialogBtn.actionKey) {
      this.buildFormWidgets(this.currentDialogBtn);
    }
  }

  /**
   * 构建当前页面的主题
   */
  initTheme() {
    if (this.formConfig && this.formConfig.container.properties) {
      if (this.formConfig.container.properties.hasBorder) {
        this.borderColor = this.formConfig.container.properties.borderColor;
      }
      this.fontColor = this.formConfig.container.properties.fontColor;
    } else {
      this.borderColor = 'transparent';
    }
  }

  /**
   * 构建当前页面要显示的按钮he表单项
   */
  buildFormWidgets(currentDialogBtn) {
    this.formConfig = getFormConfig(this.formId);
    this.initTheme();
    this.buildFormWidgetsByBtn(currentDialogBtn);
    // 放开表单规则校验
    this.buildWidgetRulesByBtn(currentDialogBtn);
    this.footBtn = currentDialogBtn.children || [];
  }

  /**
   * 根据按钮配置，构建表单组件项集合
   */
  buildFormWidgetsByBtn(currentBtn) {
    if (this.formWidgetsStack[currentBtn.actionKey]) {
      this.formWidgets = this.formWidgetsStack[currentBtn.actionKey];
    } else {
      let formSettingInBtn = currentBtn.formSetting.componentsSettings;
      let components = this.formConfig.container.components;
      if (components && components.length) {
        let filterCodesMap = this.buildFilterCodeMap(formSettingInBtn);
        let arr = this.buildFormWidgetsFn(components, filterCodesMap);
        // 先过滤数组中的空值
        arr.map(item => {
          if (item.properties && item.properties.components && item.properties.components.length) {
            item.properties.components = item.properties.components.filter((item, index) => {
              return item;
            });
          }
        });
        // 再实际赋值
        this.formWidgets = arr;
      } else {
        this.formWidgets = [];
      }
      this.formWidgetsStack[currentBtn.actionKey] = this.formWidgets;
    }
  }

  //获取node配置的表单项code
  private buildFilterCodeMap(formSettingInNode) {
    let result = {};
    formSettingInNode.forEach(item => {
      // zaa修改 -- 增加排序标识 修复js生成map时 顺序错误问题
      result[item.componentsCode] = [true, item.locationIndex];
      if (item.children) {
        result = Object.assign(result, this.buildFilterCodeMap(item.children));
      }
    });
    return result;
  }

  // 递归构建表单校验规则
  private buildFormWidgetsFn(allComponents, filterCodesMap) {
    let result: any = [];
    allComponents.forEach(compItem => {
      // zaa修改  filterCodesMap[key]的数据结构 -> [true, index]
      if (filterCodesMap[compItem.compCode] && filterCodesMap[compItem.compCode][0]) {
        if (compItem.compType == 'card') {
          compItem.properties.components = this.buildFormWidgetsFn(compItem.properties.components, filterCodesMap);
        } else if (compItem.compType == 'row') {
          compItem.properties.gridColumns = compItem.properties.gridColumns.filter(gridCol => {
            gridCol.components = gridCol.components.filter(gridCell => {
              return filterCodesMap[gridCell.compCode][0];
            });
            return gridCol;
          });
        } else if (compItem.compType == 'table') {
          compItem.properties.components = compItem.properties.components.filter(tableItem => {
            return filterCodesMap[tableItem.compCode][0];
          });
        }
        // result.push(compItem);
        // zaa修改  filterCodesMap[key]的数据结构 -> [true, index]
        const index = filterCodesMap[compItem.compCode][1]; //按照下标插入到数组中（会产生空值 需要过滤）
        result[index] = compItem;
      }
    });
    return result;
  }

  /**
   * 根据按钮配置，构建表单组件项的校验规则集合
   */
  buildWidgetRulesByBtn(currentBtn) {
    let componentsSetting = currentBtn.formSetting.componentsSettings;
    let btnFormSetting = this.buildFlowWidgetsRulesMap(componentsSetting);
    this.buildBtnWidgetsRules(this.formWidgetsRulesMap, btnFormSetting);
  }

  /**
   * 构建流程节点或者按钮中配置的表单项权限map
   */
  buildFlowWidgetsRulesMap(componentsSetting) {
    let formWidgetsRulesMap = {};
    componentsSetting.forEach(compItem => {
      formWidgetsRulesMap[compItem.componentsCode] = compItem.operation;
      if (compItem.children && compItem.children.length) {
        formWidgetsRulesMap = Object.assign(formWidgetsRulesMap, this.buildFlowWidgetsRulesMap(compItem.children));
      }
    });
    return formWidgetsRulesMap;
  }

  /**
   * 构建表单上 非表格 表单项的操作权限
   */
  buildBtnWidgetsRules(formWidgetsRulesMap, _formSetting) {
    let result: any = formWidgetsRulesMap;
    for (const compCode in _formSetting) {
      if (result && result[compCode]) {
        let operation = _formSetting[compCode];
        if (operation != 'default') {
          result[compCode][operation] = true;
        } else {
          result[compCode].required = false;
          result[compCode].disabled = false;
          result[compCode].hidden = false;
        }
        if (this.readonly) {
          result[compCode].disabled = true;
        }
      }
    }
    return result;
  }

  /**
   * 点击按钮时
   */
  handleBtnClick(operationBtn) {
    if (operationBtn.actionType === ActionTypeEnum.FLUSH) {
      this.btnObjStack.push(operationBtn);
      this.buildFormWidgetsByBtn(operationBtn);
    } else if (operationBtn.actionType === ActionTypeEnum.BACK && this.btnObjStack.length) {
      this.btnObjStack.pop();
      if (this.btnObjStack.length) {
        let currentBtn = this.btnObjStack[this.btnObjStack.length - 1];
        this.buildFormWidgetsByBtn(currentBtn);
      } else {
        this.buildFormWidgetsByBtn(this.currentDialogBtn);
      }
    } else if (operationBtn.actionType === ActionTypeEnum.CLOSE) {
      this.$emit('update:visible', false);
    } else if (operationBtn.actionType === ActionTypeEnum.DIALOG) {
      this.nestDialogBtn = { ...operationBtn };
      this.nestDialogVisible = true;
    } else if (operationBtn.actionType === ActionTypeEnum.PROCESS) {
      if (operationBtn.operation !== ActionOperationType.SAVE) {
        (this.$refs['form-' + this.formConfig.id] as any).validate(v => {
          if (v) {
            this.$emit('submit', operationBtn);
            this.$emit('update:visible', false);
          } else {
            // this.$message.warning(`校验未通过，请检查${this.currentDialogBtn.displayActionName}数据`);
            this.$message.warning(this.$t('lang_viladate_fail') as string);
          }
        });
      } else {
        this.$emit('submit', operationBtn);
        this.$emit('update:visible', false);
      }
    }
  }

  /**
   * 处理触发器
   * @param {number} param
   * @returns {number}
   */
  handleTrigger(data) {
    this.$emit('trigger', data);
  }

  /**
   * 改变组件数据源时
   */
  handleChangeDataSource({ element, dataSource }) {
    this.$emit('changeDataSource', { element, dataSource });
  }

  //   弹出框点击按钮
  handleNestDialogSubmit(btnObj: NodeActionModel) {
    if (btnObj.actionType === ActionTypeEnum.PROCESS) {
      if (btnObj.actionType === ActionTypeEnum.PROCESS) {
        if (btnObj.operation !== ActionOperationType.SAVE) {
          (<any>this.$refs['form-' + this.formConfig.id]).validate(v => {
            if (v) {
              this.$emit('submit', btnObj);
              this.$emit('update:visible', false);
            } else {
              this.$message.warning(this.$t('lang_viladate_fail') as string);
            }
          });
        } else {
          this.$emit('submit', btnObj);
          this.$emit('update:visible', false);
        }
      }
    }
  }

  @Watch('currentDialogBtn')
  changeBtn(newVal, oldVal) {
    if (newVal !== oldVal && newVal) {
      this.buildFormWidgets(newVal);
    }
  }
}
