








































































































































































































































































































import { Component, Prop, Vue, Watch, InjectReactive } from 'vue-property-decorator';
import EleText from '@/components/form-design/elements/ele-text.vue';
import EleDialogList from '@/components/form-design/elements/ele-dialog-list.vue';
import EleEditor from '@/components/form-design/elements/ele-editor.vue';
import EleRichTextEditor from '@/components/form-design/elements/ele-richTextEditor.vue';
import EleTasks from '@/components/form-design/elements/ele-tasks.vue';
import EleHtml from '@/components/form-design/elements/ele-html.vue';
import EleDivider from '@/components/form-design/elements/ele-divider.vue';
import EleImage from '@/components/form-design/elements/ele-image.vue';
import EleOccupy from '@/components/form-design/elements/ele-occupy.vue';
import EleCarousel from '@/components/form-design/elements/ele-carousel.vue';
import EleApplication from '@/components/form-design/elements/ele-application.vue';
import EleUpload from '@/components/form-design/elements/ele-upload.vue';
import EleSelect from '@/components/form-design/elements/ele-select.vue';
import EleCascader from '@/components/form-design/elements/ele-cascader.vue';
import EleTree from '@/components/form-design/elements/ele-tree.vue';
import EleRadio from '@/components/form-design/elements/ele-radio.vue';
import EleCheckbox from '@/components/form-design/elements/ele-checkbox.vue';
import EleNormalList from '@/components/form-design/elements/ele-normalList.vue';
import { WidgetData, WidgetModel, WidgetDataTypeEnum } from '@/models/form/WidgetModel';
import { WidgetEventTypeEnum } from '@/models/form/WidgetActionModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { getFormConfig } from './utils';
//属性
interface Properties {
  showLabel?: string;
  labelWidth?: string;
}
@Component({
  name: 'FormViewerItem',
  components: {
    EleText,
    EleDialogList,
    EleEditor,
    EleHtml,
    EleDivider,
    EleUpload,
    EleImage,
    EleSelect,
    EleCascader,
    EleRadio,
    EleCheckbox,
    EleTree,
    EleOccupy,
    EleCarousel,
    EleNormalList,
    EleApplication,
    EleRichTextEditor,
    EleTasks
  }
})
export default class FormViewerItem extends Vue {
  @Prop({ default: () => SystemUtil.cloneDeep(WidgetData) }) widget!: WidgetModel; // 父级传过来的表单项配置对象
  @Prop({ default: () => {} }) rules!: Record<string, any>; // 父级传过来的组件规则
  @Prop({ default: null }) value!: Record<string, any>; // 父级传过来的 值（是对象）
  @Prop({ default: () => {} }) dataSource!: any; // 父级传过来的组件规则
  @Prop({ default: '' }) triggerGroupId!: any; // 父级传过来的组件规则
  @Prop({ default: null }) colIndex!: any; // 父级传过来的索引
  @Prop({ default: '' }) parentType: string; // 父级的类型 table、row等

  @InjectReactive() formId: any;
  @InjectReactive() showBorder!: boolean;
  @InjectReactive() showCellBorder!: boolean;
  @InjectReactive() marginWidth!: number;
  @InjectReactive() formWidth!: number;

  element: WidgetModel = SystemUtil.cloneDeep(WidgetData);
  dataRule: Record<string, any> = {};
  itemDisabled: boolean = false;
  itemInited: boolean = false;
  dataValue: any = null; // 页面显示需要的值 根据组件类型决定类型
  dataSourceMap: any = {};
  screenWidth: number = 1920;
  labelWidth: number = 120;
  get _signable() {
    let flag = false;
    if (this.element.properties.signs && this.element.properties.signs.length) {
      let activedNode = this.$store.getters.activedNode;
      if (activedNode && activedNode.id) {
        if (activedNode.properties && activedNode.properties.extensions) {
          let signSetting = (activedNode.properties.extensions as any).signSetting || [];
          if (signSetting && signSetting.length) {
            flag = true;
          }
        }
      } else {
        flag = true;
      }
    }
    return flag;
  }

  get _labelPosition() {
    let formConfig = getFormConfig(this.formId);
    return this.element.properties.labelPosition || formConfig.container.properties.labelPosition || 'right';
  }

  get _labelBorder() {
    let reulst = false;
    // 去除左侧边框
    if (['normalList', 'application'].includes(this.element.compType)) return false;
    if (this.element.properties.showLabel) {
      if (!this.colIndex && this.colIndex != 0) {
        if (this.element.properties.cellBorder == true) {
          reulst = true;
        } else if ((this.element.properties.cellBorder == null || this.element.properties.cellBorder == undefined) && this.showCellBorder) {
          reulst = true;
        }
      }
    }
    return reulst;
  }

  created() {
    this.element = this.widget;
    this.buildLabelWidth();
    if (this.dataSource && this.dataSource.length) {
      this.buildDataSourceMap(this.dataSource);
    }
    this.buildElementDataValue(); // 返显已填写过的表单数据
    this.buildItemRule();

    this.$nextTick().then(res => {
      this.itemInited = true;
    });
  }

  mounted() {
    this.$nextTick(() => {
      this.buildElementEvent();
    });
    window.addEventListener('resize', this.buildLabelWidth);
  }

  buildLabelWidth() {
    let labelWidth = this.element.properties.labelWidth || 120;
    let result = (this.formWidth * labelWidth) / 1920 - 13;
    this.labelWidth = result;
    return result;
  }

  buildDataSourceMap(dataSource) {
    if (dataSource && Array.isArray(dataSource)) {
      dataSource.forEach(item => {
        this.dataSourceMap[item.code] = item.text;
        if (item.children) {
          this.buildDataSourceMap(item.children);
        }
      });
    }
  }
  //  根据传入数据构建单行规则
  buildItemRule(): Record<string, any>[] | void {
    if (!this.rules) return;
    if (this.rules.required) {
      let tmp = { required: true, validator: this.validItem, trigger: 'blur' };
      if (
        this.element.compType === 'date' ||
        this.element.compType === 'time' ||
        this.element.compType === 'select' ||
        this.element.compType === 'cascader' ||
        this.element.compType === 'inputNumber' ||
        this.element.compType === 'dialogList' ||
        this.element.compType === 'checkbox' ||
        this.element.compType === 'switch' ||
        this.element.compType === 'slider' ||
        this.element.compType === 'rate' ||
        this.element.compType === 'colorPicker' ||
        this.element.compType === 'slider'
      ) {
        tmp.trigger = 'change';
      }
      this.dataRule = tmp;
    } else {
      this.dataRule = {};
    }
    if (this.rules.disabled) {
      this.itemDisabled = true;
    } else {
      this.itemDisabled = false;
    }
  }
  //  校验单个
  validItem(rule, val, callback) {
    setTimeout(() => {
      if (rule.required) {
        let value = this.dataValue;
        let tip: any = this.$t('lang_please_enter');
        tip = tip + this.$t(this.element.compName);
        if (value || value === 0) {
          if (this.element.properties.multiple || this.element.compType === 'upload') {
            if (value.length) {
              callback();
            } else {
              callback(new Error(tip));
            }
          } else {
            callback();
          }
        } else {
          callback(new Error(tip));
        }
      } else {
        callback();
      }
    }, 300);
  }
  // 返显表单数据
  buildElementDataValue() {
    let dataValue: unknown;
    let arrayValWidgets = ['dialogList', 'upload', 'image'];
    if (this.value) {
      if (this.element.dataType == WidgetDataTypeEnum.COLLECTOR || this.element.dataType == WidgetDataTypeEnum.TREE) {
        dataValue = this.value['dataValue'] || [];
        if (!this.element.properties.multiple && Array.isArray(dataValue) && !arrayValWidgets.includes(this.element.compType)) {
          dataValue = dataValue[0] || null;
        }
      } else if (this.element.compType === 'slider' || this.element.compType === 'inputNumber' || this.element.compType === 'rate') {
        if (this.value['dataValue'] == 0) {
          dataValue = 0;
        } else {
          dataValue = this.value['dataValue'] || undefined;
        }
      } else {
        dataValue = this.value['dataValue'] || null;
      }
    } else {
      if (this.element.properties.multiple) {
        dataValue = [];
      } else if (this.element.compType === 'slider' || this.element.compType === 'inputNumber' || this.element.compType === 'rate') {
        dataValue = undefined;
      } else if (this.element.compType === 'upload') {
        dataValue = [];
      }
    }
    this.dataValue = dataValue;
  }

  // 保存数据时，构建最终的modelValue
  buildModelValue(dataValue: any) {
    if (dataValue === undefined || dataValue === null) {
      return null;
    }
    if (this.element.dataType == WidgetDataTypeEnum.COLLECTOR || this.element.dataType == WidgetDataTypeEnum.TREE) {
      let modelValueArr: any = [];
      if (!Array.isArray(dataValue)) {
        modelValueArr.push(this.dataSourceMap[dataValue]);
      } else {
        dataValue.forEach(item => {
          if (this.dataSourceMap[item]) {
            modelValueArr.push(this.dataSourceMap[item]);
          }
        });
      }
      return modelValueArr.join(',');
    } else if (!Array.isArray(dataValue)) {
      // 是input、textarea等静态数据时
      return dataValue;
    }
  }
  /**
   * 保存数据时，构建最终的dataValue
   */
  buildDataValue(dataValue) {
    if (dataValue === undefined || dataValue === null) {
      return null;
    }
    // 根据是不是静态数据 区别下情况，进行 dataValue和modelValue的赋值
    let result: any = dataValue;
    if (this.element.dataType == WidgetDataTypeEnum.COLLECTOR || this.element.dataType == WidgetDataTypeEnum.TREE) {
      if (!Array.isArray(dataValue)) {
        result = [dataValue];
      }
    }
    return result;
  }

  /**
   * 表单项值改变时，组织dataModel属性，并调用触发器
   */
  handleValueChange(dataValue) {
    let excludeComp: string[] = ['upload', 'image', 'dialogList'];
    if (!excludeComp.includes(this.element.compType)) {
      // 组合成后端需要的格式数据
      // 获取parentId
      let dataModel: any = {
        formModelId: this.element.id,
        formModelCode: this.element.compCode,
        // formModelDataType: this.element.dataType,
        rowIndex: null // rowIndex是当该项是表格内的项时，要把该项所在的行号传给rowIndex
      };
      dataModel.modelValue = this.buildModelValue(dataValue);
      dataModel.dataValue = this.buildDataValue(dataValue);
      if (this.value && this.value.id) {
        dataModel.id = this.value.id;
      }
      this.$emit('input', dataModel);
      this.triggerValueChangeEvent(dataModel);
    }
  }

  // 处理文件上传成功后
  handleFileUploadSuccess(fileList) {
    let fileNames: string[] = [];
    let fileIds: string[] = [];
    fileList.forEach(item => {
      fileNames.push(item.fileName);
      fileIds.push(item.id);
    });
    // 组合成后端需要的格式数据
    let dataModel: any = {
      formModelId: this.element.id,
      formModelCode: this.element.compCode,
      rowIndex: null, // rowIndex是当该项是表格内的项时，要把该项所在的行号传给rowIndex
      dataValue: fileIds,
      modelValue: fileNames.join(',')
    };
    if (this.value && this.value.id) {
      dataModel.id = this.value.id;
    }
    this.$emit('input', dataModel);
    this.triggerValueChangeEvent(dataModel);
  }

  // 处理文件签章
  handleFileSign(file) {
    this.$router.push({
      path: '/seal',
      query: { formId: this.formId, fileId: file.id, compCode: this.element.compCode }
    });
  }

  // 处理图片上传成功后
  handleImageUploadSuccess(fileList) {
    let fileNames: string[] = [];
    let fileIds: string[] = [];
    fileList.forEach(item => {
      fileNames.push(item.fileName);
      fileIds.push(item.id);
    });
    // 组合成后端需要的格式数据
    let dataModel: any = {
      formModelId: this.element.id,
      formModelCode: this.element.compCode,
      rowIndex: null, // rowIndex是当该项是表格内的项时，要把该项所在的行号传给rowIndex
      dataValue: fileIds,
      modelValue: fileNames.join(',')
    };
    if (this.value && this.value.id) {
      dataModel.id = this.value.id;
    }
    this.$emit('input', dataModel);
    this.triggerValueChangeEvent(dataModel);
  }

  // 处理文件上传成功后
  handleDialogListValueChange(selectData) {
    // 组合成后端需要的格式数据
    let dataModel: any = {
      formModelId: this.element.id,
      formModelCode: this.element.compCode,
      rowIndex: null, // rowIndex是当该项是表格内的项时，要把该项所在的行号传给rowIndex
      dataValue: selectData.dataValue,
      modelValue: selectData.modelValue
    };
    if (this.value && this.value.id) {
      dataModel.id = this.value.id;
    }
    this.$emit('input', dataModel);
    this.triggerValueChangeEvent(dataModel);
  }

  /**
   * 改变组件数据源时
   */
  handleChangeDataSource(dataSource) {
    this.$emit('changeDataSource', { element: this.element, dataSource });
  }

  /**
   * description  为组件添加触发器监听
   * @param {unknow}  val 选择或者输入的值
   * @returns {}
   */
  buildElementEvent() {
    if (this.element.actions && this.$refs[this.element.compCode]) {
      let dom = (this.$refs[this.element.compCode] as any).$el;
      if (this.element.compType.match(/input|select|inputNumber|textarea|date|time/)) {
        dom = dom.querySelector('.el-input__inner');
      }

      let actions = this.element.actions;
      // SINGLE_CLICK ON_FOCUS ON_BLUR KEY_ENTER OVER VALUE_CHANGE
      if (actions && actions.length) {
        actions.forEach(actionItem => {
          switch (actionItem.type) {
            case WidgetEventTypeEnum.SINGLE_CLICK:
              dom.addEventListener('click', event => this.handleElementEvent(event, actionItem.type, this.element, this.dataValue));
              break;
            case WidgetEventTypeEnum.ON_FOCUS:
              dom.addEventListener('focus', event => this.handleElementEvent(event, actionItem.type, this.element, this.dataValue));
              break;
            case WidgetEventTypeEnum.ON_BLUR:
              dom.addEventListener('blur', event => this.handleElementEvent(event, actionItem.type, this.element, this.dataValue));
              break;
            case WidgetEventTypeEnum.KEY_ENTER:
              dom.addEventListener('keyup', event => this.handleElementEvent(event, actionItem.type, this.element, this.dataValue));
              break;
            case WidgetEventTypeEnum.VALUE_CHANGE:
              break;
            default:
              break;
          }
        });
      }
    }
  }

  /**
   * description  单独处理值改变时的触发器
   * @param {unknow}  val 选择或者输入的值
   * @returns {}
   */
  triggerValueChangeEvent(dataModel) {
    if (this.itemInited) {
      if (this.element.actions) {
        let valChangeAction = this.element.actions.find(item => item.type == WidgetEventTypeEnum.VALUE_CHANGE);
        // SINGLE_CLICK ON_FOCUS ON_BLUR KEY_ENTER OVER VALUE_CHANGE
        if (valChangeAction) {
          this.handleElementEvent(null, valChangeAction.type, this.element, dataModel);
        }
      }
    }
  }

  //判断 当组件为嵌套组件时 是否有边框
  isShowBorderFn(compType: string): string {
    if (['occupy'].includes(compType)) return 'none';
    if (this.parentType == 'table') return 'none';
    if (this.showBorder) return 'normal';
    return 'none';
  }

  // 向父级抛出事件及参数
  handleElementEvent(event, eventType, widget, dataModel) {
    if (eventType == WidgetEventTypeEnum.KEY_ENTER) {
      if (event.keyCode == 13) {
        this.$emit('trigger', { eventType, widget, dataModel });
      }
    } else {
      this.$emit('trigger', { eventType, widget, dataModel });
    }
  }

  showLabelFn(value, compType, name) {
    if (['normalList', 'application'].includes(compType)) return '';
    if (value) return name;
    return '';
  }

  getLabelWidth(properties: Properties, compType: string): string {
    if (['normalList', 'application'].includes(compType)) return '0px';
    if (properties.showLabel) return `${this.labelWidth}px`;
    return '0px';
  }

  // 页面显示的格式数据
  @Watch('dataValue')
  onDataVlaueChange(newVal, oldVal) {
    if (oldVal == null && newVal == undefined) {
      // 对数字类型的表单项特殊处理
      return;
    }
    if (newVal != oldVal) {
      this.handleValueChange(newVal);
    }
  }

  @Watch('rules')
  onChangeValue(newVal: Record<string, boolean | string>) {
    if (newVal) {
      this.buildItemRule();
    }
  }

  @Watch('value')
  onVlaueChange(newVal, oldVal) {
    if (newVal != oldVal) {
      this.buildElementDataValue();
    }
  }

  @Watch('widget')
  widgetWatcher(newVal, oldVal) {
    this.element = newVal;
    this.buildElementDataValue(); // 返显已填写过的表单数据
    this.buildItemRule(); //生成规则
  }

  @Watch('dataSource')
  dataSourceWatcher(newVal, oldVal) {
    if (newVal) {
      this.buildDataSourceMap(newVal);
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.buildLabelWidth);
  }
}
