var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      class:
        ((_obj = {
          "form-viewer-item-border":
            _vm.showBorder &&
            (_vm.element.compType != "text" || _vm.element.properties.border),
          "form-viewer-item-textarea": _vm.element.compType == "textarea",
          "form-viewer-item-text": _vm.element.compType == "text",
          "form-viewer-item-checkbox": _vm.element.compType == "checkbox",
          "form-viewer-item-label-border": _vm._labelBorder,
        }),
        (_obj["form-viewer-item-label-" + _vm._labelPosition] = true),
        _obj),
      style: {
        border: _vm.isShowBorderFn(_vm.element.compType),
        backgroundColor: "transparent",
      },
      attrs: {
        label: _vm.showLabelFn(
          _vm.element.properties.showLabel,
          _vm.element.compType,
          _vm.element.displayName
        ),
        "label-width": _vm.getLabelWidth(
          _vm.element.properties,
          _vm.element.compType
        ),
        "label-position": "left",
        prop: _vm.widget.id,
        rules: _vm.dataRule,
      },
    },
    [
      _vm.element.compType == "input"
        ? [
            _c("el-input", {
              ref: _vm.element.compCode,
              staticClass: "ele-input",
              style: {
                width: _vm.element.properties.width + "%",
                fontSize: _vm.element.properties.fontSize + "px",
                color: _vm.element.properties.fontColor,
              },
              attrs: {
                placeholder: _vm.$t(_vm.element.properties.placeholder),
                disabled: _vm.itemDisabled,
                "prefix-icon": _vm.element.properties.prefixIcon,
                "suffix-icon": _vm.element.properties.suffixIcon,
                clearable: "",
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "textarea"
        ? [
            _c("el-input", {
              ref: _vm.element.compCode,
              style: { width: _vm.element.properties.width + "%" },
              attrs: {
                type: "textarea",
                rows: _vm.element.properties.rows,
                placeholder: _vm.$t(_vm.element.properties.placeholder),
                disabled: _vm.itemDisabled,
                "show-word-limit": _vm.element.properties.showWordLimit,
                maxlength: _vm.element.properties.maxlength,
                clearable: "",
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "text"
        ? [
            _c("ele-text", {
              ref: _vm.element.compCode,
              attrs: { widget: _vm.element },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "inputNumber"
        ? [
            _c("el-input-number", {
              ref: _vm.element.compCode,
              attrs: {
                min: _vm.element.properties.min,
                max: _vm.element.properties.max,
                step: _vm.element.properties.step,
                disabled: _vm.itemDisabled,
                clearable: "",
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = _vm._n($$v)
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "time"
        ? [
            _c("el-time-picker", {
              ref: _vm.element.compCode,
              attrs: {
                placeholder: _vm.$t(_vm.element.properties.placeholder),
                "value-format": "timestamp",
                disabled: _vm.itemDisabled,
                clearable: "",
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "date"
        ? [
            _c("el-date-picker", {
              ref: _vm.element.compCode,
              style: { width: _vm.element.properties.width + "%" },
              attrs: {
                type: _vm.element.properties.type,
                format: _vm.element.properties.format,
                "value-format": "timestamp",
                placeholder: _vm.$t(_vm.element.properties.placeholder),
                disabled: _vm.itemDisabled,
                clearable: "",
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "radio"
        ? [
            _c("ele-radio", {
              ref: _vm.element.compCode,
              attrs: {
                widget: _vm.element,
                dataSource: _vm.dataSource,
                triggerGroupId: _vm.triggerGroupId,
                disabled: _vm.itemDisabled,
              },
              on: { changeDataSource: _vm.handleChangeDataSource },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "checkbox"
        ? [
            _c("ele-checkbox", {
              ref: _vm.element.compCode,
              attrs: {
                widget: _vm.element,
                dataSource: _vm.dataSource,
                triggerGroupId: _vm.triggerGroupId,
                disabled: _vm.itemDisabled,
              },
              on: { changeDataSource: _vm.handleChangeDataSource },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "select"
        ? [
            _c("ele-select", {
              ref: _vm.element.compCode,
              attrs: {
                widget: _vm.element,
                dataSource: _vm.dataSource,
                triggerGroupId: _vm.triggerGroupId,
                disabled: _vm.itemDisabled,
              },
              on: { changeDataSource: _vm.handleChangeDataSource },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "cascader"
        ? [
            _c("ele-cascader", {
              ref: _vm.element.compCode,
              attrs: {
                widget: _vm.element,
                dataSource: _vm.dataSource,
                triggerGroupId: _vm.triggerGroupId,
                disabled: _vm.itemDisabled,
              },
              on: { changeDataSource: _vm.handleChangeDataSource },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "tree"
        ? [
            _c("ele-tree", {
              ref: _vm.element.compCode,
              attrs: {
                widget: _vm.element,
                dataSource: _vm.dataSource,
                triggerGroupId: _vm.triggerGroupId,
                disabled: _vm.itemDisabled,
              },
              on: { changeDataSource: _vm.handleChangeDataSource },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "dialogList"
        ? [
            _c("ele-dialog-list", {
              ref: _vm.element.compCode,
              attrs: {
                widget: _vm.element,
                pageInfo: _vm.dataSource,
                triggerGroupId: _vm.triggerGroupId,
                disabled: _vm.itemDisabled,
              },
              on: {
                "update:widget": function ($event) {
                  _vm.element = $event
                },
                change: _vm.handleDialogListValueChange,
                changeDataSource: _vm.handleChangeDataSource,
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "switch"
        ? [
            _c("el-switch", {
              ref: _vm.element.compCode,
              attrs: {
                "active-color": _vm.element.properties.activeColor,
                "inactive-color": _vm.element.properties.inactiveColor,
                "active-value": _vm.element.properties.activeValue,
                "inactive-value": _vm.element.properties.inactiveValue,
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "slider"
        ? [
            _c("el-slider", {
              ref: _vm.element.compCode,
              attrs: {
                step: _vm.element.properties.step,
                "show-stops": _vm.element.properties.showStops,
                min: _vm.element.properties.min,
                max: _vm.element.properties.max,
                disabled: _vm.itemDisabled,
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "rate"
        ? [
            _c("el-rate", {
              ref: _vm.element.compCode,
              attrs: {
                "allow-half": _vm.element.properties.allowHalf,
                "show-score": _vm.element.properties.showScore,
                max: _vm.element.properties.max,
                disabled: _vm.itemDisabled,
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "editor"
        ? [
            _c("ele-editor", {
              ref: _vm.element.compCode,
              attrs: {
                properties: _vm.element.properties,
                disabled: _vm.itemDisabled,
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "richTextEditor"
        ? [
            _c("EleRichTextEditor", {
              ref: _vm.element.compCode,
              attrs: { properties: _vm.element.properties },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "colorPicker"
        ? [
            _c("el-color-picker", {
              ref: _vm.element.compCode,
              attrs: {
                size: _vm.element.properties.size,
                "show-alpha": _vm.element.properties.showAlpha,
                disabled: _vm.itemDisabled,
                predefine: _vm.element.properties.predefine,
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "upload"
        ? [
            _c("ele-upload", {
              ref: _vm.element.compCode,
              staticClass: "upload-demo",
              style: { width: _vm.element.properties.width + "%" },
              attrs: {
                action: _vm.element.properties.action,
                accept: _vm.element.properties.accepts,
                multiple: _vm.element.properties.multiple,
                buttonText: _vm.element.properties.buttonText,
                disabled: _vm.itemDisabled,
                limit: _vm.element.properties.limit,
                "show-file-list": _vm.element.properties.showFileList,
                "list-type": _vm.element.properties.listType,
                signs: _vm.element.properties.signs,
                signable: _vm._signable,
              },
              on: {
                success: _vm.handleFileUploadSuccess,
                sign: _vm.handleFileSign,
              },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "image"
        ? [
            _c("ele-image", {
              ref: _vm.element.compCode,
              staticClass: "upload-demo",
              attrs: { widget: _vm.element, disabled: _vm.itemDisabled },
              on: { success: _vm.handleImageUploadSuccess },
              model: {
                value: _vm.dataValue,
                callback: function ($$v) {
                  _vm.dataValue = $$v
                },
                expression: "dataValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "occupy"
        ? [
            _c("ele-occupy", {
              ref: _vm.element.compCode,
              staticClass: "upload-demo",
              attrs: { isDisplay: true, widget: _vm.element },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "normalList"
        ? [
            _c("ele-normalList", {
              ref: _vm.element.compCode,
              attrs: {
                pageInfo: _vm.dataSource,
                widget: _vm.widget,
                isDisplay: true,
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "carousel"
        ? [
            _c("ele-carousel", {
              ref: _vm.element.compCode,
              staticClass: "upload-demo",
              attrs: { isDisplay: true, widget: _vm.element },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "application"
        ? [
            _c("ele-application", {
              ref: _vm.element.compCode,
              attrs: {
                isDisplay: true,
                pageInfo: _vm.dataSource,
                widget: _vm.element,
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "button"
        ? [
            _c(
              "el-button",
              {
                ref: _vm.element.compCode,
                attrs: {
                  type: _vm.element.properties.type,
                  disabled: _vm.itemDisabled,
                  size: _vm.element.properties.size,
                  plain: _vm.element.properties.plain,
                  round: _vm.element.properties.round,
                  circle: _vm.element.properties.circle,
                  loading: _vm.element.properties.loading,
                  icon: _vm.element.properties.icon,
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t(_vm.element.compName)) + " ")]
            ),
          ]
        : _vm._e(),
      _vm.element.compType == "divider"
        ? [
            _c("ele-divider", {
              ref: _vm.element.compCode,
              attrs: { value: _vm.element.compName, widget: _vm.element },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "alert"
        ? [
            _c("el-alert", {
              ref: _vm.element.compCode,
              attrs: {
                title: _vm.element.properties.title,
                type: _vm.element.properties.type,
                effect: _vm.element.properties.effect,
                closable: _vm.element.properties.closable,
                center: _vm.element.properties.center,
                "show-icon": _vm.element.properties.showIcon,
                "close-text": _vm.element.properties.closeText,
                description: _vm.element.properties.description,
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "link"
        ? [
            _c(
              "el-link",
              {
                ref: _vm.element.compCode,
                attrs: {
                  type: _vm.element.properties.type,
                  href: _vm.element.properties.href,
                  disabled: _vm.itemDisabled,
                  target: _vm.element.properties.target,
                },
              },
              [_vm._v(_vm._s(_vm.element.properties.text))]
            ),
          ]
        : _vm._e(),
      _vm.element.compType == "html"
        ? [
            _c("ele-html", {
              ref: _vm.element.compCode,
              attrs: {
                properties: _vm.element.properties,
                value: _vm.dataValue,
              },
            }),
          ]
        : _vm._e(),
      _vm.element.compType == "tasks"
        ? [
            _c("ele-tasks", {
              ref: _vm.element.compCode,
              attrs: { properties: _vm.element.properties, isDisplay: true },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }