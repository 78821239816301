var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      key: "row-" + _vm.element.compCode,
      staticClass: "form-viewer-item-grid",
    },
    _vm._l(_vm.element.properties.gridColumns, function (column, columnIndex) {
      return _c(
        "el-col",
        {
          key: "grid-" + _vm.element.compCode + "column-" + column.index,
          staticClass: "form-viewer-item-grid-col",
          attrs: { span: column.span },
        },
        [
          _vm._l(column.components, function (col, colIndex) {
            return [
              col.properties.gridColumns
                ? _c("FormViewerGrid", {
                    key: "row-" + col.compCode,
                    style: { borderLeft: _vm.showBorder ? "1px solid" : "0px" },
                    attrs: { element: col },
                    on: {
                      trigger: _vm.handleTrigger,
                      changeDataSource: _vm.handleChangeDataSource,
                    },
                  })
                : col.compType == "tab"
                ? _c("form-viewer-tab", {
                    key: "tab-" + col.compCode,
                    attrs: {
                      element: col,
                      dataSource: _vm.formWidgetsDataSourceMap,
                    },
                    on: { changeDataSource: _vm.handleChangeDataSource },
                  })
                : !_vm.formWidgetsRulesMap[col.compCode] ||
                  !_vm.formWidgetsRulesMap[col.compCode].hidden
                ? _c("form-viewer-item", {
                    key: col.compCode,
                    staticClass: "form-viewer-item",
                    style: {
                      borderTopWidth:
                        colIndex == 0 ||
                        !_vm.showBorder ||
                        (col.compType == "text" &&
                          col.properties.border == false)
                          ? "0px"
                          : "1px",
                    },
                    attrs: {
                      widget: col,
                      dataSource: _vm.formWidgetsDataSourceMap[col.compCode],
                      triggerGroupId: _vm.formWidgetsTriggerMap[col.compCode],
                      rules: _vm.formWidgetsRulesMap[col.compCode],
                      colIndex: colIndex,
                    },
                    on: {
                      trigger: _vm.handleTrigger,
                      changeDataSource: _vm.handleChangeDataSource,
                    },
                    model: {
                      value: _vm.formWidgetsValueMap[col.compCode],
                      callback: function ($$v) {
                        _vm.$set(_vm.formWidgetsValueMap, col.compCode, $$v)
                      },
                      expression: "formWidgetsValueMap[col.compCode]",
                    },
                  })
                : _vm._e(),
            ]
          }),
          _vm.showLabelBorder(column)
            ? _c("div", {
                key:
                  "grid-" +
                  _vm.element.compCode +
                  "column-" +
                  column.index +
                  "_label",
                staticClass: "form-grid-col-label-border",
                style: { left: _vm.columnsLabelBorder[columnIndex] },
              })
            : _vm._e(),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }