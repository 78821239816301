




































































import { Component, Prop, Vue, Watch, InjectReactive } from 'vue-property-decorator';
import FormViewerItem from './form-viewer-item.vue';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import FormViewerDialog from './form-viewer-dialog.vue';
import { WidgetModel } from '@/models/form/WidgetModel';
import { buildFormDataForSave, getFormWidgetMap } from './utils';
import { validWidgetCondition } from '@/api/form-design/FormApi';
@Component({
  name: 'FormViewerTable',
  components: {
    FormViewerItem,
    FormViewerDialog
  }
})
export default class extends Vue {
  @Prop({ default: {} }) element!: WidgetModel; // 流程图中使用表单的地方 传过来的表单id
  @Prop({ default: () => {} }) rules!: Record<string, any>; // 父级传过来的组件规则

  @InjectReactive() flowId: any;
  @InjectReactive() formId: any;
  @InjectReactive() formDataId: any;

  @InjectReactive() formWidgetsValueMap!: any;
  @InjectReactive() formWidgetsDataSourceMap!: any;
  @InjectReactive() formWidgetsRulesMap!: any;
  @InjectReactive() formWidgetsTriggerMap!: any;

  @InjectReactive() historyTableWidgetsRulesMap: any; //回显时表格每行的校验规则
  @InjectReactive() historyTableWidgetsDataSourceMap: any; //回显时表格每行的表单项，每项的数据源
  @InjectReactive() historyTableWidgetsTriggerMap: any; //回显时表格每行的表单项，每项的数据源

  @InjectReactive() defaultTableWidgetsValueMap: any; //表格每行的校验规则
  @InjectReactive() defaultTableWidgetsRulesMap: any; //表格每行的校验规则
  @InjectReactive() defaultTableWidgetsDataSourceMap: any; //表格每行的表单项，每项的数据源

  tableWidgetsRuleList: any[] = []; //表格每行的校验规则
  tableWidgetsDataSourceList: any[] = []; //表格每行的表单项，每项的数据源
  tableWidgetsTriggerList: any[] = []; //表格每行的表单项，每项的触发器
  itemDisabled: boolean = false;

  get _formWidgetMap() {
    return getFormWidgetMap(this.formId);
  }

  get _activedNode() {
    return this.$store.getters.activedNode;
  }

  created() {
    this.tableWidgetsRuleList = this.historyTableWidgetsRulesMap[this.element.compCode] || [];
    let rowDataLength = this.tableWidgetsRuleList.length;
    let tableWidgetsDataSourceList = this.historyTableWidgetsDataSourceMap[this.element.compCode];
    if (tableWidgetsDataSourceList) {
      this.tableWidgetsDataSourceList = tableWidgetsDataSourceList || [];
    } else if (rowDataLength) {
      for (let index = 0; index < rowDataLength; index++) {
        this.tableWidgetsDataSourceList.push({});
      }
    }

    let tableWidgetsTriggerList = this.historyTableWidgetsTriggerMap[this.element.compCode];
    if (tableWidgetsTriggerList) {
      this.tableWidgetsTriggerList = tableWidgetsTriggerList || [];
    } else if (rowDataLength) {
      for (let index = 0; index < rowDataLength; index++) {
        this.tableWidgetsTriggerList.push({});
      }
    }
    this.buildItemRule();
  }

  //  根据传入数据构建单行规则
  buildItemRule(): Record<string, any>[] | void {
    if (!this.rules) return;
    if (this.rules.disabled) {
      this.itemDisabled = true;
    } else {
      this.itemDisabled = false;
    }
  }

  // 表格上部 添加按钮
  addTableRow(element, index?: number) {
    let tableData = this.formWidgetsValueMap[element.compCode];
    if (tableData && tableData.length >= element.properties.maxRowNum) {
      return;
    }
    let defaultRowData = {};
    let tableRowValueMap = this.defaultTableWidgetsValueMap[element.compCode];
    if (tableRowValueMap && tableRowValueMap.length) {
      defaultRowData = SystemUtil.cloneDeep(tableRowValueMap[0]);
    } else {
      defaultRowData = {};
    }
    if (!this.formWidgetsValueMap[element.compCode]) {
      this.$set(this.formWidgetsValueMap, element.compCode, [defaultRowData]);
    } else {
      this.formWidgetsValueMap[element.compCode].push(defaultRowData);
    }

    let defaultRowRules = this.defaultTableWidgetsRulesMap[element.compCode];
    if (defaultRowRules && defaultRowRules.length) {
      this.tableWidgetsRuleList.push(SystemUtil.cloneDeep(defaultRowRules[0]));
    } else {
      this.tableWidgetsRuleList.push({});
    }

    let defaultRowDataSources = this.defaultTableWidgetsDataSourceMap[element.compCode];
    if (defaultRowDataSources && defaultRowDataSources.length) {
      this.tableWidgetsDataSourceList.push(SystemUtil.cloneDeep(defaultRowDataSources[0]));
    } else {
      this.tableWidgetsDataSourceList.push({});
    }

    this.tableWidgetsTriggerList.push({});
  }

  // 表格内删除
  deleteTableRow(data, index) {
    // data.splice(index, 1);
    this.formWidgetsValueMap[this.element.compCode].splice(index, 1);
    this.tableWidgetsRuleList.splice(index, 1);
    this.tableWidgetsDataSourceList.splice(index, 1);
    this.tableWidgetsTriggerList.splice(index, 1);
  }

  /**
   * 表格汇总
   */
  handleTableSummary(param, widget) {
    const { columns, data } = param;
    const sums: any[] = [];
    let summaryColumnKeys: any = widget.properties.summaryColumnKeys;
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = this.$t('lang_total');
        return;
      }
      if (summaryColumnKeys.includes(column.property)) {
        const values = data.map(item => {
          if (item[column.property]) {
            return Number(item[column.property].dataValue || 0);
          }
          return 0;
        });
        if (!values.every((value: any) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = 'N/A';
        }
      } else {
        sums[index] = '';
      }
    });
    return sums;
  }

  /**
   * 处理触发器
   * @param {number} param
   * @returns {number}
   */
  handleTrigger({ widget }, rowIndex?: number) {
    let params: any = {
      formId: this.formId,
      formModelDataList: buildFormDataForSave(this.formWidgetsValueMap, this.formDataId).filter(a => a.formModelCode),
      formModelId: widget.id
    };
    if (this.formDataId) {
      params.formDataId = this.formDataId;
    }
    if (rowIndex || rowIndex == 0) {
      params.rowIndex = rowIndex;
    }
    if (this._activedNode && this._activedNode.id) {
      params.arguments = {
        var_current_node_id: this._activedNode.id,
        var_process_id: this.flowId
      };
    }
    // 校验action
    validWidgetCondition(params).then((res: any) => {
      if (res.code == '1' && res.data) {
        let triggerResult = res.data;
        let noDataModelList: any[] = triggerResult.noDataModelList || [];
        let simpleDataSource: any[] = triggerResult.simpleDataList || [];
        let complexDataSource: any[] = triggerResult.complexDataList || [];
        let allDataSource = simpleDataSource.concat(complexDataSource).concat(noDataModelList);
        allDataSource.forEach(item => {
          if (!item.formModelParentCode || item.rowIndex == rowIndex) {
            let target = this.findWidgetByCode(item.formModelCode);
            if (item.dataList || item.pageInfo) {
              let dataSource = item.dataList || item.pageInfo;
              this.replaceWidgetDataSource(target, dataSource, widget, rowIndex);
            }
            if (item.defaultValue) {
              this.replaceWidgetDataValue(target, item.defaultValue, widget, rowIndex);
            }
            if (item.operation) {
              this.replaceWidgetOperation(target, item.operation, widget, rowIndex);
            }
            if (item.triggerGroupId) {
              this.replaceWidgetTrigger(target, item.triggerGroupId, widget, rowIndex);
            }
          } else {
          }
        });
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 替换默认值,如果之前没有值
  replaceWidgetDataValue(widget: any, defaultValue: any, sourceWidget, rowIndex) {
    if (widget.parentComponents) {
      if (widget.parentComponents.compCode == 'table') {
        if (widget.parentComponents.compCode == sourceWidget.parentComponents.compCode) {
          let tableData = SystemUtil.cloneDeep(this.formWidgetsValueMap[widget.parentComponents.compCode]);
          let rowData = tableData[rowIndex];
          rowData[widget.compCode] = defaultValue;
          this.formWidgetsValueMap[widget.parentComponents.compCode] = tableData;
        }
      } else {
        if (!this.formWidgetsValueMap[widget.compCode]) {
          this.$set(this.formWidgetsValueMap, widget.compCode, defaultValue);
        } else {
          this.formWidgetsValueMap[widget.compCode] = defaultValue;
        }
      }
    } else {
      if (!this.formWidgetsValueMap[widget.compCode]) {
        this.$set(this.formWidgetsValueMap, widget.compCode, defaultValue);
      } else {
        this.formWidgetsValueMap[widget.compCode] = defaultValue;
      }
    }
  }
  // 构建表单项的数据源
  replaceWidgetDataSource(widget, dataSource, sourceWidget, rowIndex) {
    if (widget.dataSource) {
      if (widget.parentComponents && sourceWidget.parentComponents && sourceWidget.parentComponents.compType == 'table') {
        if (widget.parentComponents.compCode == sourceWidget.parentComponents.compCode) {
          let rowDataSources = this.tableWidgetsDataSourceList[rowIndex];
          rowDataSources[widget.compCode] = dataSource;
          this.historyTableWidgetsDataSourceMap[this.element.compCode] = this.tableWidgetsDataSourceList;
        }
      } else {
        this.formWidgetsDataSourceMap[this.element.compCode] = dataSource;
      }
    }
  }
  // 构建表单项的操作属性
  replaceWidgetOperation(widget, operation, sourceWidget, rowIndex) {
    let tmp = {
      required: false,
      hidden: false,
      disabled: false,
      label: widget.compName
    };
    if (operation != 'default') {
      tmp[operation] = true;
    }
    if (widget.parentComponents && sourceWidget.parentComponents && sourceWidget.parentComponents.compType == 'table') {
      if (widget.parentComponents.compCode == sourceWidget.parentComponents.compCode) {
        let tableRowRules = this.tableWidgetsRuleList[rowIndex];
        this.$set(tableRowRules, widget.compCode, tmp);
        this.historyTableWidgetsRulesMap[this.element.compCode] = this.tableWidgetsDataSourceList;
      }
    } else {
      // 影响表格外组件的操作权限
      this.$set(this.formWidgetsRulesMap, widget.compCode, tmp);
    }
  }

  // 替换触发器,如果之前没有值
  replaceWidgetTrigger(widget: any, triggerGroupId: any, sourceWidget, rowIndex) {
    if (widget.parentComponents && sourceWidget.parentComponents && sourceWidget.parentComponents.compType == 'table') {
      if (widget.parentComponents.compCode == sourceWidget.parentComponents.compCode) {
        let rowWidgetsTriggerMap = this.tableWidgetsTriggerList[rowIndex];
        rowWidgetsTriggerMap[widget.compCode] = triggerGroupId;
        this.historyTableWidgetsTriggerMap[this.element.compCode] = this.tableWidgetsTriggerList;
      }
    } else {
      this.formWidgetsTriggerMap[this.element.compCode] = triggerGroupId;
    }
  }

  /**
   * 根据表单项code获取表单项
   */
  findWidgetByCode(code: string): WidgetModel | undefined {
    return this._formWidgetMap[code];
  }

  /**
   * 改变组件数据源时
   */
  handleChangeDataSource({ element, dataSource }, rowIndex) {
    let rowDataSources = this.tableWidgetsDataSourceList[rowIndex];
    rowDataSources[element.compCode] = dataSource;
    this.historyTableWidgetsDataSourceMap[element.compCode] = this.tableWidgetsDataSourceList;
  }

  @Watch('rules')
  onChangeValue(newVal: Record<string, boolean | string>) {
    if (newVal) {
      this.buildItemRule();
    }
  }
}
