var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con" },
    [
      _c(
        "el-card",
        {
          style: {
            width: _vm.element.properties.width + "%",
            height: _vm.element.properties.height + "px",
            border: _vm.showBorder,
          },
        },
        [
          _vm.element.properties.showLabel
            ? _c("div", { staticClass: "header" }, [
                _vm._v(" " + _vm._s(_vm.$t(_vm.element.compName)) + " "),
                _c(
                  "div",
                  { staticClass: "header-more", on: { click: _vm.clickMore } },
                  [
                    _c("span", [
                      _vm._v("更多"),
                      _c("i", { staticClass: "el-icon-d-arrow-right" }),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.tabList, function (tabItem, index) {
              return _c(
                "el-tab-pane",
                {
                  key: tabItem.id,
                  attrs: {
                    label: _vm.$t(tabItem.compName),
                    name: tabItem.compCode,
                  },
                },
                [
                  _c("form-viewer-item", {
                    key: "single-" + tabItem.compCode,
                    staticClass: "form-viewer-item",
                    staticStyle: { padding: "0" },
                    attrs: {
                      widget: _vm.element.properties.components[index],
                      dataSource:
                        _vm.formWidgetsDataSourceMap[tabItem.compCode],
                      triggerGroupId:
                        _vm.formWidgetsTriggerMap[tabItem.compCode],
                      rules: _vm.formWidgetsRulesMap[tabItem.compCode],
                    },
                    on: { changeDataSource: _vm.handleChangeDataSource },
                    model: {
                      value: _vm.formWidgetsValueMap[tabItem.compCode],
                      callback: function ($$v) {
                        _vm.$set(_vm.formWidgetsValueMap, tabItem.compCode, $$v)
                      },
                      expression: "formWidgetsValueMap[tabItem.compCode]",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }